


export const initialLoginState = {
    lEmail: 'brandon@email.com',
    lPassword: '123456',
}
export const initialRegisterState = {
    rName: 'brandon',
    rEmail: 'brandon@email.com',
    rPassword1: '123456',
    rPassword2: '123456',
}